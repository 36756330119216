.page-body {
  height: calc(100vh - 136px);
  background: #f2f2f2;
}
.page-body .password-forgot {
  width: 680px;
  height: 700px;
  background-color: #fff;
  border-radius: 16px;
  padding: 24px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.page-body .password-forgot .cancel-button {
  font-size: 24px;
  position: absolute;
  top: 32px;
  left: 32px;
  cursor: pointer;
}
.page-body .password-forgot__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}
.page-body .password-forgot__body, .page-body .password-forgot__ft {
  width: 313px;
  margin: 0 auto;
}
.page-body .password-forgot .input-text {
  height: 56px;
  padding: 16px 20px;
  gap: 4px;
  border-radius: 16px;
  border: 1px solid var(--text-3, rgb(134, 144, 156));
  background-color: #fff;
}
.page-body .password-forgot .submit-btn,
.page-body .password-forgot .submit-btn:hover {
  height: 68px;
  padding: 16px 24px;
  gap: 8px;
  border-radius: 16px;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: rgb(255, 255, 255);
}
.page-body .password-forgot .logo-content {
  margin-top: 80px;
}
.page-body .arco-input {
  caret-color: var(--Color, rgb(22, 93, 255));
}
.page-body .line {
  width: 313px;
  height: 1px;
  background-color: var(--black-10, rgba(28, 28, 28, 0.1));
  overflow: hidden;
  margin: 0 auto;
}